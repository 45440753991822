<template>
  <div class="d-flex flex-column">
    <v-btn
      color="accent"
      @click="generateData()"
    >Generate data
    </v-btn>
    <div class="d-flex flex-column mb-6">
      <span>Dates only</span>
      <span
        v-for="(d, index) in dates"
        :key="index"
      >
        <div class="d-flex flex-row">
          <span class="mr-6">{{d}}</span>
          <calendar-date :date="d" />

        </div>
      </span>

    </div>

    <div class="d-flex flex-column">
      <span>Dates and times</span>
      <v-switch
        v-model='showTime'
        label='Show time'
      ></v-switch>
      <span
        v-for="(d, index) in datesWithTime"
        :key="index"
      >
        <div class="d-flex flex-row">
          <span class="mr-6">{{d}}</span>
          <calendar-date
            :date="d"
            :show-time="showTime"
          />

        </div>
      </span>

    </div>
  </div>

</template>

<script>
import _ from 'lodash'

export default {
  components: {
    CalendarDate: () => import('@/components/calendar-date')
  },
  created () {
    this.generateData()
  },
  data () {
    return {
      showTime: true,
      dates: [],
      datesWithTime: []
    }
  },
  methods: {
    generateData () {
      const d = this.moment().startOf('day')
      this.dates = []
      let i
      for (i = -5; i < 6; i++) {
        this.dates.push(this.moment(d).add(i, 'day'))
      }

      this.datesWithTime = []
      for (i = -5; i < 6; i++) {
        let x = this.moment(d).add(i, 'day').add(_.random(4, 10), 'hour').add(_.random(2, 55), 'minute').add(_.random(1, 55), 'second')
        this.datesWithTime.push(x)

        if (i === 0) {
          let j
          for (j = 18; j <= 23; j++) {
            x = this.moment(d).add(i, 'day').add(j, 'hour').add(_.random(2, 55), 'minute').add(_.random(1, 55), 'second')
            this.datesWithTime.push(x)
          }
        }
      }
    }
  }

}
</script>

<style lang="stylus" scoped></style>
